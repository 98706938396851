<template>
  <section class="d-flex flex-column align-items-center history" id="history_">
    <div class="container">
      <div class="d-flex flex-column history__body">
        <h1><span class="style--h1">Еринская</span><br>минеральная вода</h1>
        <div class="history__body__content">
          <h3>А Вы знаете, с чего всё начиналось?</h3>
          <div class="d-flex flex-column align-items-center content__box">
            <p>«В 1870 году известный геолог и горный инженер Геннадий Данилович Романовский впервые детально описал минералогические пласты окрестностей Подольска, обратив внимание на минеральные воды у села Ерино...»</p>
            <a href="" class="btn--detail"><span class="btn--detail__text">подробнее</span></a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: "SectionHistory"
}
</script>

<style lang="scss">

$color-text-default: #5183AB;
$color-p-default: #12558E;

.history {
  z-index: 55;
  &__body {
    padding-top: 143px;
    background-image: url("~@/assets/images/chapel_.png");
    background-repeat: no-repeat;
    background-position: 110% 50%;
    background-size: 70%;

    @media (max-device-width: 575.98px) {
      background-image:
          linear-gradient(180deg, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0.5) 100%),
          url("~@/assets/images/chapel_.png");
      padding-top: 108px;
      background-size: 100%;
      background-position: 50% 70%;
    }

    h1 {
      font-family: 'Panton-Bold', serif;
      font-style: normal;
      font-weight: 500;
      font-size: 50px;
      line-height: 50px;
      letter-spacing: -2px;
      color: $color-text-default;

      @media (max-device-width: 575.98px) {
        font-size: 35px;
        line-height: 35px;
      }

      .style--h1 {
        font-family: 'Panton-Bold', serif;
        letter-spacing: 1px;
        font-size: 70px;

        @media (max-device-width: 575.98px) {
          font-size: 49px;
        }
      }
    }

    &__content {
      h3 {
        margin-top: 40px;
        font-family: 'Panton', serif;
        font-style: normal;
        font-weight: 700;
        font-size: 36px;
        line-height: 36px;
        color: #BFA074;

        @media (max-device-width: 575.98px) {
          font-size: 25px;
          line-height: 25px;
        }
      }

      .content__box{
        max-width: 700px;

        p {
          margin-top: 20px;
          font-family: 'Panton-SemiBold', serif;
          font-style: normal;
          font-weight: 600;
          font-size: 28px;
          line-height: 38px;
          color: $color-text-default;
          text-align: justify;

          @media (max-device-width: 575.98px) {
            font-size: 19px;
            line-height: 22px;
            text-align: left;
          }
        }

        .btn--detail {
          background: rgba(255, 255, 255, 0.75);
          box-shadow: rgba(0, 28, 126, 0.2) 0 7px 29px, inset 0px -10px 16px #DFF0FF;
          border-radius: 30px;
          text-decoration: none;
          margin:  20px 20px 40px 20px;
          padding: 15px 25px;
          transition: all 0.5s ease;

          @media (max-device-width: 575.98px) {
            margin-bottom: 50px;
            padding: 8px 16px;
          }

          &:hover {
            box-shadow: rgba(0, 28, 126, 0.2) 0 0 10px, inset 0 0 16px #DFF0FF;
          }

          &:active {
            background: rgba(255, 255, 255, 0.75);
            box-shadow: rgba(0, 28, 126, 0.1) 0 0 5px, inset 0 10px 16px rgba(212, 229, 240, 0.73);
            backdrop-filter: blur(15px);
          }

          &__text {
            font-family: 'Panton-Bold', serif;
            font-style: normal;
            font-weight: 600;
            font-size: 28px;
            line-height: 35px;
            color: $color-text-default;

            @media (max-device-width: 575.98px) {
              font-size: 18px;
              line-height: 25px;
            }
          }
        }
      }
    }
  }
}









</style>