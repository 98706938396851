<template>
  <section class="d-flex justify-content-center delivery" id="delivery_">
    <div class="container">
      <h1><span class="style--h1">Доставка</span><br>воды</h1>
      <div class="d-flex flex-column justify-content-between delivery__box">
        <div class="d-flex justify-content-between box--col-1">
          <p><b>Доставка</b> продукции осуществляется на следующий день после оформления заказа.</p>
          <p><b>Поставка</b> осуществляется с понедельника по пятницу с <b>9</b> до <b>18.</b></p>
        </div>
        <div class="box--col-2">
          <p>При <b>первом заказе</b> питьевой воды в многооборотной таре, взимается залог за бутыль в размере <b>350 руб.</b> за 1 ед. &nbsp; В случае, если у вас остались бутыли от другого поставщика, можно принять их на обмен при условии пригодности их дальнейшего использования.</p>
          <p>При второй и последующих поставках воды, пустые бутыли обмениваются на заполненные, оплачивается только стоимость воды.</p>
          <p>Мы осуществляем доставку на территории <b>Подольска</b> и <b>Подольского района.</b></p>
          <p>В случае заказа оптом, доставка согласовывается отдельно с менеджером.</p>
<!--          <ul>-->
<!--            <li>-->
<!--              <p><b>Бесплатно</b>  при покупке от <b>трех</b> 19 литровых бутылей воды.</p>-->
<!--            </li>-->
<!--            <li><p><b>Бесплатно</b> при заказе от <b>10 упаковок</b> 5 л или 1,5 л бутылок.</p>-->
<!--            </li>-->
<!--          </ul>-->
        </div>
      </div>
    </div>
  </section>


</template>

<script>
export default {
  name: "SectionDelivery"
}
</script>

<style lang="scss">

$color-text-default: #5183AB;
$color-p-default: #12558E;

.delivery {
  background-image:url("~@/assets/images/splash_.jpg");
  background-repeat: no-repeat;
  background-position: 0 20%;
  background-size:100%;
  padding-bottom: 143px;

  @media (max-device-width: 575.98px) {
    background-position: 0 60%;
    background-size:200%;
    padding-bottom: 60px;
  }

  h1 {
    margin-top: 141px;
    font-family: 'Panton-Bold', serif;
    font-style: normal;
    font-weight: 500;
    font-size: 50px;
    line-height: 50px;
    letter-spacing: 1px;
    color: $color-text-default;

    @media (max-device-width: 575.98px) {
      font-size: 35px;
      line-height: 35px;
      margin-top: 107px;
    }

    .style--h1 {
      font-family: 'Panton-Bold', serif;
      letter-spacing: 1px;
      font-size: 70px;

      @media (max-device-width: 575.98px) {
        font-size: 49px;
      }
    }
  }

  &__box {
    margin-top: 60px;
    padding: 35px;
    background: rgba(255, 255, 255, 0.65);
    border: 10px solid rgba(96, 149, 192, 0.05);
    box-shadow: 0 5px 60px rgb(0 28 126 / 15%), inset 0 -10px 26px #f1f1f1;
    backdrop-filter: blur(55px);
    border-radius: 30px;

    @media (max-device-width: 575.98px) {
      margin-top: 20px;
      padding: 20px;
      border-radius: 25px;
      box-shadow: 0 5px 20px rgb(0 28 126 / 15%), inset 0 -10px 26px #f1f1f1;
    }

    p {
      font-family: 'Panton', serif;
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 35px;
      text-align: left;
      color: $color-p-default;

      @media (max-device-width: 575.98px) {
        font-size: 16px;
        line-height: 18px;
        text-align: justify;
      }
    }

    .box--col-1 {

      @media (max-device-width: 575.98px) {
        flex-direction: column;
      }

      p {
        max-width: 45%;

        @media (max-device-width: 575.98px) {
          max-width: 100%;
        }
      }
    }

    .box--col-2 {
      ul {
        margin:0;

        &:nth-child(1) {
          margin-bottom: 16px;
        }

        p {
          margin: 16px 0 0 0;
        }
      }
    }
  }
}
</style>