<template>
  <section class="d-flex justify-content-between home" id="home_">
    <div class="d-flex flex-column justify-content-between home__body">
      <div class="d-flex justify-content-center home__body__header"></div>
      <div class="container">
        <div class="d-flex flex-column justify-content-center align-items-center home__body__content">
          <div class="d-flex justify-content-between align-items-center title-logo">
            <div class="align-self-start img--one"><img class="istochnik" src="~@/assets/images/istochnik.png" alt="Источник"></div>
            <div class="align-self-start img--two"><img class="erino" src="~@/assets/images/erino.png" alt="Ерино"></div>
          </div>
          <div class="d-flex flex-column align-items-center text-subtitle">
            <p class="align-self-start">Минеральная природная столовая,</p>
            <p class="align-self-end">высшей категории питьевая вода.</p>
          </div>
        </div>
      </div>
      <div class="d-flex flex-column align-items-center home__body__footer">
        <div class="d-flex flex-column align-items-center box__buttons">
          <a href="#catalog_" class="btn--catalog"><span class="btn--catalog__text">каталог продукции</span></a>
          <a href="#history_" class="btn--arrow"><img src="~@/assets/images/arrow.svg" alt=""></a>
        </div>
        <div class="box__waves">
          <svg class="waves" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 28" preserveAspectRatio="none" shape-rendering="auto">
            <defs>
              <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"></path>
            </defs>
            <g class="parallax">
              <!--            <use xlink:href="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7"></use>-->
              <use xlink:href="#gentle-wave" x="48" y="4" fill="rgba(255,255,255,0.5)"></use>
              <use xlink:href="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)"></use>
              <use xlink:href="#gentle-wave" x="48" y="6" fill="#FFFFFF"></use>
            </g>
          </svg>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "SectionHome"
}
</script>

<style lang="scss">

// Маленькие устройства (телефоны с горизонтальной ориентацией, 576 пикселей и выше)
@media (min-width: 576px) {
  .img--one {
    padding-right: 5%;
  }
}
// Средние устройства (планшеты, ширина 768px и выше)
@media (min-width: 768px) {
  .img--one {
    padding-right: 5%;
  }
}
// Большие устройства (десктопы, ширина 992px и выше)
@media (min-width: 992px) {
  .img--one {
    padding-right: 5%;
  }
}
// Устройства X-Large (большие настольные компьютеры, ширина 1200 пикселей и выше)
@media (min-width: 1200px) {
  .img--one {
    padding-right: 5%;
  }
}
// XX-большие устройства (большие настольные компьютеры,  ширина 1400 пикселей и выше)
@media (min-width: 1400px) {
  .img--one {
    padding-right: 0;
  }
}

$color-txt-home: #5997C7;

.home {
  width: 100%;
  height: 100vh;
  //overflow: hidden;
  background-image: linear-gradient(90deg, #8DBCE7 0%, #C1DDF5 15%, #D8EBFA 40%, #D8EBFA 60%, #C1DDF5 85%, #8DBCE7 100%);

  @media (max-device-width: 575.98px) {
    height: calc(100vh - 61px)
  }

  &__body {
    width: 100%;
    height: 100%;

    &__header {
      width: 100%;
      height: 25vh;
      background-image: url("~@/assets/images/waterwave-full_.png");
      background-repeat: no-repeat;
      background-position: center bottom;
      background-size: cover;
      z-index: 99;

      @media (max-device-width: 575.98px) {
        height: 20vh;
        background-position: center top;
      }
    }

    &__content {
      .title-logo {
        width: 100%;

        @media (max-device-width: 575.98px) {
        flex-direction: column;
        justify-content: center;
        margin-top: 20px;
        }


        &::after {
          position: absolute;
          content: "";
          height: 120px;
          background: rgba(255, 255, 255, 0.8);
          filter: blur(100px);
          border-radius: 12vh;
          z-index: 1;

          @media (max-device-width: 575.98px) {
            display: none !important;
          }

          @media (min-width: 768px) {
            width: 720px;
          }

          @media (min-width: 992px) {
            width: 960px;
          }

          @media (min-width: 1200px) {
            width: 1140px;
          }

          @media (min-width: 1400px) {
            width: 1320px;
          }
        }

        .img--one {
          z-index: 99;

          img {
            pointer-events: none;
            max-width: 100%;
            height: auto;
          }
        }

        .img--two {
          z-index: 99;

          @media (max-device-width: 575.98px) {
            text-align: center;
          }

          img {
            pointer-events: none;
            max-width: 100%;
            height: auto;

            @media (max-device-width: 575.98px) {
              max-width: 64%;
            }
          }
        }
      }

      .text-subtitle {
        width: 74.5%;
        margin-top: 50px;

        @media (max-device-width: 575.98px) {
          margin-top: 20px;
        }

        p {
          font-family: 'Panton-Black', serif;
          font-style: normal;
          font-weight: 900;
          font-size: 36px;
          line-height: 45px;
          color: $color-txt-home;
          text-shadow: -1px -1px 1px rgba(255, 255, 255, 0.7), 1px 1px 1px rgba(255, 255, 255, 0.7);
          z-index: 99;

          @media (max-width: 575.98px) {
            font-size: 20px;
            line-height: 15px;
            align-self: center!important;
          }
        }

        @media (max-width: 575.98px) {
          width: 100%;
        }
      }
    }

    &__footer {
      .box__waves {
        width: 100%;
        position: absolute;
        bottom: 0;
        overflow: hidden;

        .waves {
          position:relative;
          width: 100%;
          height:35vh;
          margin-bottom:-7px; /*Fix for safari gap*/
          min-height:100px;
          max-height:310px;

          @media (max-device-width: 575.98px) {
            width: 150%;
            max-height:110px;
          }

          @media (min-width: 1200px) {
            max-height:210px;
          }
        }

        .parallax > use {
          animation: move-forever 25s cubic-bezier(.55,.5,.45,.5)     infinite;
        }
        .parallax > use:nth-child(1) {
          animation-delay: -2s;
          animation-duration: 18s;
        }
        .parallax > use:nth-child(2) {
          animation-delay: -2s;
          animation-duration: 18s;
        }
        .parallax > use:nth-child(3) {
          animation-delay: -2s;
          animation-duration: 18s;
        }
        .parallax > use:nth-child(4) {
          animation-delay: -5s;
          animation-duration: 30s;
        }

        @keyframes move-forever {
          0% {
            transform: translate3d(-90px,0,0);
          }
          100% {
            transform: translate3d(85px,0,0);
          }
        }
      }

      .box__buttons {
        z-index: 99;

        .btn--catalog {
          background: rgba(255, 255, 255, 0.75);
          box-shadow: rgba(0, 28, 126, 0.2) 0 7px 29px, inset 0px -10px 16px #DFF0FF;
          border-radius: 30px;
          text-decoration: none;
          margin:  20px 20px 100px 20px;
          padding: 15px 25px;
          transition: all 0.5s ease;

          @media (max-device-width: 575.98px) {
            margin-bottom: 50px;
            padding: 8px 16px;
          }

          &:hover {
            box-shadow: rgba(0, 28, 126, 0.2) 0 0 10px, inset 0 0 16px #DFF0FF;
          }

          &:active {
            background: rgba(255, 255, 255, 0.75);
            box-shadow: rgba(0, 28, 126, 0.1) 0 0 5px, inset 0 10px 16px rgba(212, 229, 240, 0.73);
            backdrop-filter: blur(15px);
          }

          &__text {
            font-family: 'Panton-Bold', serif;
            font-style: normal;
            font-weight: 600;
            font-size: 28px;
            line-height: 35px;
            color: $color-txt-home;

            @media (max-device-width: 575.98px) {
              font-size: 18px;
              line-height: 25px;
            }
          }
        }

        .btn--arrow {
          margin: 0 1% 10px;
          padding: 25px;
          animation: arrow_fade_move_down 2.5s ease-in-out infinite;

          img {
            width: 46px;
            height: 22px;
          }

          &:hover {
            animation-play-state: paused;
          }

          @keyframes arrow_fade_move_down {
            0%   { transform:translate(0,-20px); opacity: 0;  }
            50%  { opacity: 1;  }
            100% { transform:translate(0,20px); opacity: 0; }
          }
        }
      }
    }
  }
}


</style>