<template>
    <div class="d-flex justify-content-between cart-item">
      <div class="d-flex align-items-center cart-item__image">
        <img :src=" require('../assets/images/bottle/' + cart_item_data.image) " alt="#">
      </div>
      <div class="d-flex flex-column cart-item__body">
        <div class="d-flex justify-content-between align-items-center body__header">
          <div class="body__header__name">
            <p><span class="brand">{{ cart_item_data.brand }}</span></p>
            <p><span class="name">{{ cart_item_data.name }}</span></p>
            <p><span class="origin">{{ cart_item_data.origin }}</span></p>
          </div>
          <div class="d-flex justify-content-center align-items-center body__header__info">
            <div class="d-flex">
              <div class="d-flex align-items-center body__header__info--type"><p>{{ cart_item_data.type }}</p></div>
              <div class="d-flex align-items-center body__header__info--volume"><p>{{ cart_item_data.volume }}</p></div>
            </div>
            <div class="d-flex align-items-center align-self-end body__header__info--min-order">
              <p> мин. заказ {{ minAmount }} шт.</p>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-between body__panel">
          <div class="d-flex justify-content-center align-items-center body__panel--text-calc">
            <p>{{ cart_item_data.price }}</p><div class="d-flex body__panel--total-price__rub"><div></div></div>
          </div>
          <div class="d-flex justify-content-center align-items-center body__panel--text-x">
            <p> x </p>
          </div>
          <div class="d-flex justify-content-center align-items-center body__panel--quantity">
            <div class="decrement"><button :disabled="!stateButtonDecrement" @click="decrementItem"></button></div>
            <p>{{ cart_item_data.quantity }}</p>
            <div class="increment"><button @click="incrementItem"></button></div>
          </div>
          <div class="d-flex justify-content-center align-items-center body__panel--total-price">
            <span class="total-txt"><p>итого&nbsp;&nbsp;</p></span><p>{{ totalPricePackage }}</p><div class="d-flex body__panel--total-price__rub"><div></div></div>
          </div>
          <div class="d-flex justify-content-center align-items-center body__panel--delete">
            <button @click="deleteFromCart"></button>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  name: "v-cart-item",
  props: {
    cart_item_data: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      stateButtonDecrement: true,
    }
  },
  computed: {
    totalPricePackage() {
      return (this.cart_item_data.quantity * this.cart_item_data.price)
    },
    minAmount() {
      return (this.cart_item_data.minimum_lot * this.cart_item_data.amount_in_package)
    }
  },
  methods: {
    incrementItem() {
      this.$emit('increment');
    },
    decrementItem() {
      this.$emit('decrement');
    },
    deleteFromCart() {
      this.$emit('deleteFromCart');
    }
  },
  mounted() {
  },
  watch: {

  }
}
</script>

<style lang="scss">
$color-card: #ffffff;
$color-p-default: #12558E;
$background-forms: #ebebeb;

.cart-item {
  background: $background-forms;
  border-radius: 24px;
  padding: 8px;
  margin-bottom: 12px;
  transition: all .5s ease;
  z-index: 11;
  cursor: pointer;
  user-select: none;
  outline: solid 2px #fff;

  &:hover {
    outline: solid 2px #c5d2de;
    z-index: 55;
  }

  @media (max-device-width: 575.98px) {
    margin-bottom: 8px;
  }

  &__image {
    width: 108px;
    height: 132px;
    background: $color-card;
    border-radius: 18px;
    pointer-events: none;
    margin-right: 6px;

    @media (max-device-width: 575.98px) {
      height: auto;
    }

    img {
      height: 132px;

      @media (max-device-width: 575.98px) {
        height: 90px;
      }
    }
  }

  &__body {
    width: 100%;

    @media (max-device-width: 575.98px) {
      width: auto;
    }

    .body__header {
      border-radius: 18px;
      background: $color-card;
      //height: 80px;
      padding: 6px;

      @media (max-device-width: 575.98px) {
        flex-direction: column;
      }

      &__name {
        text-align: left;
        font-family: 'Panton-Bold', serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        color: $color-p-default;
        padding-left: 5%;

        @media (max-device-width: 575.98px) {
          text-align: center;
          line-height: 14px;
          padding-left: 0;
        }

        p {
          padding: 0;
          margin: 0;
        }

        .brand {
          font-size: 18px;

          @media (max-device-width: 575.98px) {
            font-size: 14px;
            line-height: 16px
          }
        }

        .name {
          font-size: 18px;

          @media (max-device-width: 575.98px) {
            font-size: 14px;
            line-height: 16px
          }
        }

        .origin {
          font-family: 'Panton', serif;
          font-size: 16px;

          @media (max-device-width: 575.98px) {
            font-size: 12px;
            line-height: 12px
          }
        }
      }

      &__info {
        flex-direction: column;

        @media (max-device-width: 575.98px) {
          flex-direction: row;
        }

        &--type {
          background: #DBFFE7;
          border-radius: 12px;
          padding: 4px 8px;
          margin: 3px;

          @media (max-device-width: 575.98px) {
            padding: 2px 4px;
          }

          p {
            font-family: 'Panton', serif;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            text-align: center;
            color: $color-p-default;
            margin: 0;

            @media (max-device-width: 575.98px) {
              font-size: 12px;
            }
          }
        }

        &--volume {
          background: rgba(255, 161, 19, 0.15);
          border-radius: 12px;
          padding: 4px 8px;
          margin: 3px;
          text-align: center;

          @media (max-device-width: 575.98px) {
            padding: 2px 4px;
          }

          p {
            font-family: 'Panton', serif;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            text-align: center;
            color: $color-p-default;
            margin: 0;

            @media (max-device-width: 575.98px) {
              font-size: 12px;
            }
          }
        }

        &--min-order {
          background: rgba(141, 188, 195, 0.15);
          border-radius: 12px;
          padding: 4px 8px;
          margin: 3px;
          text-align: center;

          @media (max-device-width: 575.98px) {
            padding: 2px 4px;
          }

          p {
            font-family: 'Panton', serif;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            text-align: center;
            color: $color-p-default;
            margin: 0;

            @media (max-device-width: 575.98px) {
              font-size: 12px;
            }
          }
        }
      }
    }

    .body__panel {
      border-radius: 18px;
      background: $color-card;
      height: 46px;
      margin-top: 6px;
      padding: 6px;

      @media (max-device-width: 575.98px) {
        width: 280px;
        height: 30px;
      }

      &--quantity {
        background: #e9f3f8;
        border-radius: 12px;
        padding: 0 4px;

        .disabled-decrement{
          cursor: not-allowed;

          button {
            background-color: #a7d2f7;
          }
        }

        .decrement {
          height: 26px;
          border-radius: 6px;
          transition: all .5s ease;

          @media (max-device-width: 575.98px) {
            //height: 100%;
          }

          &:hover {
            background: rgb(231, 195, 195);
          }

          &:active {
            background: rgb(255, 213, 213);
          }

          button {
            width: 14px;
            height: 26px;
            border: none;
            mask-image: url("~@/assets/images/minus-line.svg");
            mask-repeat: no-repeat;
            margin: 0 3px 0 3px;
            background-color: #7EA0BD;
            transition: all 0.5s ease;
            z-index: 99;

            @media (max-device-width: 575.98px) {
             //height: 46px;
            }

            &:hover {
              background-color: rgba(98, 51, 51);
            }
          }
        }

        .increment {
          height: 26px;
          border-radius: 6px;
          transition: all 0.5s ease;

          &:hover {
            background: rgb(179, 235, 178);
          }

          &:active {
            background: rgba(193, 255, 192);
          }


          button {
            width: 14px;
            height: 26px;
            border: none;
            mask-image: url("~@/assets/images/plus-outline.svg");
            mask-repeat: no-repeat;
            margin: 0 3px 0 3px;
            background-color: #7EA0BD;
            transition: all .5s ease;

            &:hover {
              background-color: rgba(20, 96, 10);
            }
          }
        }

        p {
          width: 30px;
          font-family: 'Panton', serif;
          font-style: normal;
          font-weight: 600;
          font-size: 20px;
          line-height: 26px;
          text-align: center;
          color: $color-p-default;
          //padding-top: 2px;
          margin: 0 5px;
          transition: all .5s ease;

          @media (max-device-width: 575.98px) {
            font-size: 14px;
            width: auto;
          }
        }
      }

      &--text-calc {
        p{
          font-family: 'Panton', serif;
          font-style: normal;
          font-weight: 600;
          font-size: 20px;
          line-height: 36px;
          text-align: center;
          color: $color-p-default;
          margin: 0 5px 0 10px;

          @media (max-device-width: 575.98px) {
            font-size: 14px;
          }
        }
      }

      &--text-x {
        p{
          font-family: 'Panton', serif;
          font-style: normal;
          font-weight: 600;
          font-size: 20px;
          line-height: 36px;
          text-align: center;
          color: $color-p-default;
          margin: 0;

          @media (max-device-width: 575.98px) {
            font-size: 14px;
            padding: 8px;
          }
        }
      }

      &--total-price {
        width: 220px ;

        .total-txt {

          @media (max-device-width: 575.98px) {
            font-family: 'Panton', serif;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
          }
        }

        p{
          font-family: 'Panton-Bold', serif;
          font-style: normal;
          font-weight: 400;
          font-size: 22px;
          line-height: 36px;
          text-align: center;
          color: $color-p-default;
          margin: 0 5px 0 0;

          @media (max-device-width: 575.98px) {
            font-size: 14px;
          }
        }

        &__rub {
          width: 16px;
          height: 16px;
          background: rgba(82, 117, 155, 0.15);
          border-radius: 4px;
          justify-content: center;
          align-items: center;

          @media (max-device-width: 575.98px) {
            transform: scale(0.75);
          }

          div {
            display: block;
            width: 14px;
            height: 14px;
            mask-image: url("~@/assets/images/rub.svg");
            mask-repeat: no-repeat;
            background-color: $color-p-default;
            margin: 0;
          }
        }
      }

      &--delete{
        width: 34px;
        height: 34px;
        border-radius: 12px;
        transition: all .8s ease;

        @media (max-device-width: 575.98px) {
          width: auto;
          height: auto;
          transform: scale(0.7);
        }

        &:hover {
          background: #ebebeb;
        }

        button {
          width: 22px;
          height: 22px;
          border: none;
          mask-image: url("~@/assets/images/delete-cart.svg");
          background: #CD6464;
          transition: all .2s ease;

          &:active {
            background-color: #025cb5;
          }
        }
      }
    }
  }


}
</style>