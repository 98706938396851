<template>
  <section class="d-flex justify-content-center footer" id="footer_">
    <div class="footer--bg"></div>
    <div class="container">
      <div class="d-flex flex-column align-items-center footer__box">
        <h3><span class="style-f-yerino">© Источник Ерино</span> <span class="year">2012 - 2024</span></h3>
        <p>Минеральная природная столовая, высшей категории питьевая вода. <span class="spacing">Доставка воды по Москве и области.</span></p>
      </div>
    </div>
  </section>


</template>

<script>
export default {
  name: "SectionFooter"
}
</script>

<style lang="scss">
$color-p-footer: #ffffff;
//$color-p-footer: #2e536a;

.footer {
  background-image: linear-gradient(90deg, #8DBCE7 0%, #C1DDF5 15%, #D8EBFA 40%, #D8EBFA 60%, #C1DDF5 85%, #8DBCE7 100%);

  &--bg {
    position: absolute;
    width: 100%;
    height:118px;
    background: linear-gradient(0deg, #669bc4 60%, rgba(60, 127, 185, 0) 300%);
    z-index: 1;
  }

  .container {
    z-index: 22;
  }

  &__box {
    padding: 16px 0;

    @media (max-device-width: 575.98px) {
      padding: 18px 0;
    }

    .style-f-yerino{
      font-family: 'Antikvar-v2', serif;
      font-style: normal;
      //font-weight:bold;
      font-size: 32px;
      line-height: 37px;
      letter-spacing: 0.04em;
      color: $color-p-footer;

      @media (max-device-width: 575.98px) {
        font-size: 18px;
        line-height: 27px;
        margin-bottom: 18px;
      }
    }

    p{
      font-family: 'Panton', serif;
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;
      color: $color-p-footer;

      @media (max-device-width: 575.98px) {
        font-size: 12px;
        line-height: 20px;
        text-align: center;
        margin-bottom: 0;
      }
    }

    .year {
      font-family: 'Philosopher', serif;
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 27px;
      letter-spacing: 0.04em;
      color: $color-p-footer;

      @media (max-device-width: 575.98px) {
        font-size: 18px;
        line-height: 23px;
      }
    }

  }
}

</style>