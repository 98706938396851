<template>
  <section class="d-flex flex-column justify-content-center catalog" >
    <div class="catalog__wave--top">
      <div class="wave">
        <svg class="waves" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 28" preserveAspectRatio="none" shape-rendering="auto">
          <defs>
            <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"></path>
          </defs>
          <g class="parallax">
            <!--            <use xlink:href="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7"></use>-->
            <use xlink:href="#gentle-wave" x="48" y="4" fill="rgba(255,255,255,0.5)"></use>
            <use xlink:href="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)"></use>
            <use xlink:href="#gentle-wave" x="48" y="6" fill="#FFFFFF"></use>
          </g>
        </svg>
      </div>
      <div class="catalog-scroll-breakpoint"  id="catalog_" ></div>
    </div>
    <div class="container">
      <div class="d-flex flex-column catalog__body">
        <div class="d-flex justify-content-between catalog__body__text--block-one">
          <div class="group-bottle"></div>
          <div class="text--block-one--style">
            <h1><span class="style--h1">Каталог</span><br>продукции</h1>
            <div class="block-subtitle">
              <p>У нас вы можете заказать "Источник Ерино" минеральную воду от производителя оптом и в розницу, в ёмкастях от 0,6 до 19 литров.</p>
              <p>Доставка воды по Москве и области.</p>
            </div>
          </div>
        </div>
        <v-cart
            :cart_data="CART"
        />
        <div class="d-flex flex-wrap align-items-center catalog__body__box-item">
          <CatalogItem
            v-for="product in PRODUCTS"
            :key="product.id"
            :product_data="product"
            @addToCart="addToCart"
          />
        </div>
      </div>
    </div>
    <div class="catalog__wave--bottom">
      <div class="wave">
        <svg class="waves" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 28" preserveAspectRatio="none" shape-rendering="auto">
          <defs>
            <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"></path>
          </defs>
          <g class="parallax">
            <!--            <use xlink:href="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7"></use>-->
            <use xlink:href="#gentle-wave" x="48" y="4" fill="rgba(255,255,255,0.5)"></use>
            <use xlink:href="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)"></use>
            <use xlink:href="#gentle-wave" x="48" y="6" fill="#FFFFFF"></use>
          </g>
        </svg>
      </div>
    </div>
  </section>
</template>

<script>
import CatalogItem from "@/components/CatalogItem";
import VCart from "@/components/v-cart";
import {mapActions, mapGetters} from 'vuex'

export default {
  name: "SectionCatalog",
  components: {
    VCart,
    CatalogItem
  },
  props: {},
  data () {
    return {
      isElVisible: 1
    }
  },
  computed: {
    ...mapGetters([
        'PRODUCTS',
        'CART'
    ])
  },
  methods: {
    ...mapActions([
        'GET_PRODUCTS_FROM_API',
        'ADD_TO_CART'
    ]),
    addToCart(data) {
      this.ADD_TO_CART(data)
    }
  },
  watch: {},
  mounted() {
    this.GET_PRODUCTS_FROM_API()
        .then((response) => {
          if (response.data) {
            console.log('Data arrived!')
          }
        });
  }
}

</script>

<style lang="scss">
$color-text-default: #5183AB;
$color-p-default: #12558E;

.catalog {
  z-index: 54;
  background-image: linear-gradient(90deg, #8DBCE7 0%, #C1DDF5 15%, #D8EBFA 40%, #D8EBFA 60%, #C1DDF5 85%, #8DBCE7 100%);

  &__body {
    &__text--block-one {
      .group-bottle {
        position: relative;
        left: -45px;
        height: 450px;
        width: 450px;
        background-image: url("~@/assets/images/group-bottle.png");
        background-position: center;
        background-repeat: no-repeat;

        @media (max-device-width: 575.98px) {
          display: none;
        }
      }

      .text--block-one--style {
        max-width: 840px;

        @media (max-device-width: 575.98px) {
          background-image: url("~@/assets/images/group-bottle.png");
          background-position:125% -30%;
          background-repeat: no-repeat;
          background-size: 63%;
        }

        h1 {
          margin-top: 49px;
          font-family: 'Panton-Bold', serif;
          font-style: normal;
          font-weight: 500;
          font-size: 50px;
          line-height: 50px;
          letter-spacing: 1px;
          color: $color-text-default;

          @media (max-device-width: 575.98px) {
            font-size: 35px;
            line-height: 35px;
          }

          .style--h1 {
            font-family: 'Panton-Bold', serif;
            letter-spacing: 1px;
            font-size: 70px;

            @media (max-device-width: 575.98px) {
              font-size: 49px;
            }
          }
        }

        .block-subtitle {
          max-width: 915px;
          margin-top: 60px;
          padding: 20px;
          background: rgba(96, 149, 192, 0.1);
          border: 10px solid rgba(96, 149, 192, 0.05);
          letter-spacing: 1px;
          border-radius: 35px;
          backdrop-filter: blur(8px);

          @media (max-device-width: 575.98px) {
            margin-top: 20px;
            padding: 8px;
            border-radius: 25px;
            letter-spacing: 0;
          }

          p {
            font-family: 'Panton', serif;
            font-style: normal;
            font-weight: 400;
            font-size: 26px;
            line-height: 30px;
            text-align: justify;
            color: #456e8e;
            margin-bottom: 0 !important;

            @media (max-device-width: 575.98px) {
              font-size: 16px;
              line-height: 18px;
            }

            &:nth-child(2) {
              margin-top: 20px;
            }
          }
        }
      }
    }

    &__box-item {
      margin-top: 90px;
      background: rgba(96, 149, 192, 0.1);
      border: 10px solid rgba(96, 149, 192, 0.05);
      //box-shadow: 0 5px 20px rgb(0 28 126 / 15%), inset 0 -5px 16px rgba(241, 241, 241, 0.36);
      border-radius: 35px;
      overflow: hidden;
      padding: 50px 50px 0 50px;
      margin-bottom: 30px ;
      justify-content: space-between;

      @media (max-device-width: 575.98px) {
        margin-top: 30px;
        border-radius: 25px;
        padding: 26px 26px 0 26px;
        justify-content: center !important;
      }
    }
  }

  &__wave--top {
    position: relative;
    width: 100%;
    background-image: linear-gradient(90deg, #8DBCE7 0%, #C1DDF5 15%, #D8EBFA 40%, #D8EBFA 60%, #C1DDF5 85%, #8DBCE7 100%);

    .catalog-scroll-breakpoint{
      position: absolute;
      top:54%;

      @media (max-device-width: 575.98px) {
        top:45%;
      }
    }

    .wave {
      width: 100%;
      transform: rotate(180deg);
      overflow: hidden;

      .waves {
        width: 100%;
        height:35vh;
        margin-bottom:-7px;
        min-height:100px;
        max-height:310px;

        @media (max-device-width: 575.98px) {
          width: 150%;
          max-height:110px;
        }

        // Устройства X-Large (большие настольные компьютеры, ширина 1200 пикселей и выше)
        @media (min-width: 1200px) {
          max-height:210px;
        }
      }

      .parallax > use {
        animation: move-forever--history 25s cubic-bezier(.55,.5,.45,.5) infinite;
      }
      .parallax > use:nth-child(1) {
        animation-delay: -2s;
        animation-duration: 18s;
      }
      .parallax > use:nth-child(2) {
        animation-delay: -2s;
        animation-duration: 18s;
      }
      .parallax > use:nth-child(3) {
        animation-delay: -2s;
        animation-duration: 18s;
      }
      .parallax > use:nth-child(4) {
        animation-delay: -5s;
        animation-duration: 30s;
      }

      @keyframes move-forever--history{
        0% {
          transform: translate3d(85px,0,0);
        }
        100% {
          transform: translate3d(-90px,0,0);
        }
      }
    }
  }
  &__wave--bottom {
    position: relative;
    width: 100%;
    background-image: linear-gradient(90deg, #8DBCE7 0%, #C1DDF5 15%, #D8EBFA 40%, #D8EBFA 60%, #C1DDF5 85%, #8DBCE7 100%);

    .wave {
      width: 100%;
      overflow: hidden;

      .waves {
        width: 100%;
        height:35vh;
        margin-bottom:-7px; /*Fix for safari gap*/
        min-height:100px;
        max-height:310px;

        @media (max-device-width: 575.98px) {
          width: 150%;
          max-height:110px;
        }

        // Устройства X-Large (большие настольные компьютеры, ширина 1200 пикселей и выше)
        @media (min-width: 1200px) {
          max-height:210px;
        }
      }

      .parallax > use {
        animation: move-forever--catalog--bottom 25s cubic-bezier(.55,.5,.45,.5) infinite;
      }
      .parallax > use:nth-child(1) {
        animation-delay: -2s;
        animation-duration: 18s;
      }
      .parallax > use:nth-child(2) {
        animation-delay: -2s;
        animation-duration: 18s;
      }
      .parallax > use:nth-child(3) {
        animation-delay: -2s;
        animation-duration: 18s;
      }
      .parallax > use:nth-child(4) {
        animation-delay: -5s;
        animation-duration: 30s;
      }

      @keyframes move-forever--catalog--bottom{
        0% {
          transform: translate3d(-90px,0,0);
        }
        100% {
          transform: translate3d(85px,0,0);
        }
      }
    }
  }

}





</style>