<template>
  <section class="d-flex flex-column justify-content-center contacts" id="">
    <div class="contacts__wave--top">
      <div class="wave">
        <svg class="waves" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 28" preserveAspectRatio="none" shape-rendering="auto">
          <defs>
            <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"></path>
          </defs>
          <g class="parallax">
            <!--            <use xlink:href="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7"></use>-->
            <use xlink:href="#gentle-wave" x="48" y="4" fill="rgba(255,255,255,0.5)"></use>
            <use xlink:href="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)"></use>
            <use xlink:href="#gentle-wave" x="48" y="6" fill="#FFFFFF"></use>
          </g>
        </svg>
      </div>
      <div class="contacts-scroll-breakpoint" id="contacts_"></div>
    </div>
    <div class="container container-bg">
      <div class="contacts__body">
        <h1>Контакты</h1>
        <div class="d-flex  align-items-center map-container">
          <div class="d-inline-flex text-box-contacts">
            <div class="d-flex flex-column">
              <div class="spacing address">
                <h4>Адрес производства:</h4>
                <p><span>142102, г. Москва, поселение Рязановское, <br>п. Ерино тер.санаторий Ерино.</span></p>
              </div>
              <div class="spacing">
                <h4>Электронная почта:</h4>
                <p>mail@istochnik-erino.ru</p>
              </div>
              <div class="d-flex flex-column justify-content-between media-phone">
                <h4>Контактные телефоны:</h4>
                <ul>
                  <li>Отдел продаж - <span class="br-delimiter">-</span> 8 (991) 207-58-58</li>
                  <li>Магазин п.Ерино тел - <span class="br-delimiter">-</span> 8 (495) 849-32-64</li>
                  <li>Магазин Рязановское шоссе 21А - <span class="br-delimiter">-</span> 8 (495) 849-32-67</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="box-map">
            <iframe src="https://yandex.ru/map-widget/v1/?um=constructor%3A69c009ff2df4101dfb2b84418fa1f903c76a491d4b2adcfe0893d368e0bd038f&amp;source=constructor" width="100%" height="670"></iframe>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "SectionContacts"
}
</script>

<style lang="scss">

$color-text-default: #5183AB;
$color-p-default: #12558E;

@media screen and (max-width: 576px) {
  .media-phone {
    flex-direction: column !important;
  }
  .spacing-p {
    margin: 0 !important;
  }
}

@media screen and (max-width: 1280px) {
  .box-contacts {
    //width:1200px;
  }
}

@media screen and (min-width: 1281px) {
  .box-contacts {
    //width:90vw;
  }
}

@media screen and (min-width: 1650px) {
  .box-contacts {
    //width:1600px;
  }
}

.contacts {
  background-image: linear-gradient(90deg, #8DBCE7 0%, #C1DDF5 15%, #D8EBFA 40%, #D8EBFA 60%, #C1DDF5 85%, #8DBCE7 100%);

  &__wave--top {
    position: relative;
    width: 100%;
    background-image: linear-gradient(90deg, #8DBCE7 0%, #C1DDF5 15%, #D8EBFA 40%, #D8EBFA 60%, #C1DDF5 85%, #8DBCE7 100%);

    .contacts-scroll-breakpoint{
      position: absolute;
      top:54%;

      @media (max-device-width: 575.98px) {
        top:45%;
      }
    }

    .wave {
      width: 100%;
      transform: rotate(180deg);
      overflow: hidden;

      .waves {
        width: 100%;
        height: 35vh;
        margin-bottom: -7px;
        min-height: 100px;
        max-height: 310px;

        @media (max-device-width: 575.98px) {
          width: 150%;
          max-height: 110px;
        }

        // Устройства X-Large (большие настольные компьютеры, ширина 1200 пикселей и выше)
        @media (min-width: 1200px) {
          max-height: 210px;
        }
      }

      .parallax > use {
        animation: move-forever--history 25s cubic-bezier(.55, .5, .45, .5) infinite;
      }

      .parallax > use:nth-child(1) {
        animation-delay: -2s;
        animation-duration: 18s;
      }

      .parallax > use:nth-child(2) {
        animation-delay: -2s;
        animation-duration: 18s;
      }

      .parallax > use:nth-child(3) {
        animation-delay: -2s;
        animation-duration: 18s;
      }

      .parallax > use:nth-child(4) {
        animation-delay: -5s;
        animation-duration: 30s;
      }

      @keyframes move-forever--history {
        0% {
          transform: translate3d(85px, 0, 0);
        }
        100% {
          transform: translate3d(-90px, 0, 0);
        }
      }
    }
  }


  &__body {

    h1 {
      margin-top: 49px;
      font-family: 'Panton-Bold', serif;
      font-style: normal;
      font-weight: 500;
      font-size: 70px;
      line-height: 50px;
      letter-spacing: 1px;
      color: $color-text-default;

      @media (max-device-width: 575.98px) {
        font-size: 49px;
      }
    }

    .map-container {
      margin-top: 60px;
      position: relative;
      padding-bottom: 60px;

      @media (max-device-width: 575.98px) {
        margin-top: 20px;
        flex-direction: column;
      }

      .box-map {
        width: 100%;
        height: 670px;
        box-shadow: 0 15px 60px rgb(0 28 126 / 15%), inset 0 -10px 26px #f1f1f1;
        border-radius: 30px;
        overflow: hidden;

        iframe {
          border-radius: 30px;
          box-shadow: 0 15px 60px rgb(0 28 126 / 15%), inset 0 -10px 26px #f1f1f1;
          border: 10px solid rgba(96, 149, 192, 0.05);
        }
      }
    }

    .text-box-contacts {
      position: absolute;
      width: 55%;
      max-width: 600px;
      margin-left: 75px;
      padding: 25px;
      background: rgba(255, 255, 255, 0.5);
      border: 10px solid rgba(96, 149, 192, 0.05);
      box-shadow: 0 5px 20px rgb(0 28 126 / 15%), inset 0 -5px 16px rgba(241, 241, 241, 0.36);
      backdrop-filter: blur(10px);
      border-radius: 30px;

      @media (max-device-width: 575.98px) {
        position: relative;
        margin-left: 0;
        width: 100%;
        margin-top: 20px;
        margin-bottom: 20px;
        padding: 17px;
        text-align: center;
      }

      .spacing {
        padding-bottom: 1rem;
      }

      .address {
        @media (max-device-width: 575.98px) {
          width: 100%;
        }
      }

      h4 {
        font-family: 'Panton', serif;
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 29px;
        color: $color-p-default;

        @media (max-device-width: 575.98px) {
          font-size: 18px;
          line-height: 23px;
        }
      }

      p {
        font-family: 'Panton', serif;
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 29px;
        color: $color-p-default;
        margin: 0;

        @media (max-device-width: 575.98px) {
          font-size: 18px;
          line-height: 23px;
        }
      }
      ul {
        margin: 0;


        @media (max-device-width: 575.98px) {
          ul {
            padding-left: 0; /* Сдвигаем список влево */
          }
        }
      }


      li {
        font-family: 'Panton', serif;
        font-weight: 400;
        font-size: 20px;
        line-height: 25px;
        color: $color-p-default;

        @media (max-device-width: 575.98px) {
          font-size: 18px;
          line-height: 23px;
          list-style-type: none; /* Убираем маркеры */
          padding-bottom: 15px;
        }

        &:before {
          color: $color-p-default;
        }
      }

      .br-delimiter {
        display: none;
        color: rgba(0, 0, 0, 0.0);

        @media (max-device-width: 575.98px) {
          display: flex;
          width: 100%;
          height: 5px;
        }
      }


    }
  }
}

</style>