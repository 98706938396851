<template>
  <nav
      class="navbar navbar-expand-lg fixed-top"
      id="navbar-header"
      v-bind:class="{ scrolled: isActive }"
  >
    <div class="container align-items-center navbar-content">
      <a class="d-flex align-items-center content__brand" href="#">
        <img
            src="@/assets/images/logo_e.svg"
            alt="Источник Ерино"
            v-bind:class="{ scrolled__logo_img: isActive }"
        >
        <span
            class="content__brand__name"
            v-bind:class="{ scrolled__logo_txt: isActive }"
        >
          Источник Ерино</span>
      </a>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse justify-content-end content__text" id="navbarSupportedContent">
        <ul class="navbar-nav d-flex align-items-center mb-lx-0">
          <li class="nav-item px-1 mx-1">
            <a class="nav-link" href="#history_">О нас </a>
          </li>
          <li class="nav-item px-1 mx-1">
            <a class="nav-link" href="#catalog_">Продукция</a>
          </li>
          <li class="nav-item px-1 mx-1">
            <a class="nav-link" href="#delivery_">Доставка</a>
          </li>
          <li class="nav-item px-1 mx-1">
            <a class="nav-link" href="#contacts_">Контакты</a>
          </li>
        </ul>
      </div>
      <div class="justify-content-center align-items-center content__phone">
        <img src="~@/assets/images/phone.svg" alt="phone yerinskaya">
        <span>8 (991) 207-58-58</span>
      </div>
      <div class="d-flex justify-content-center align-items-center content__cart">
        <div class="cart-box-relative">
          <div v-if="cart_lenght>=1" class="cart-quantity"><div class="cart-quantity__number">{{ cart_lenght }}</div></div>
          <button type="button" data-bs-toggle="modal" data-bs-target="#exampleModal"></button>
        </div>
      </div>
    </div>
  </nav>

</template>

<script>

export default {
  name: "HeaderNavbar",
  props: {
    cart_data: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      isActive: false,
      cart_quantity: ''
    }
  },
  computed: {
    cart_lenght() {
      return this.$store.state.cart.length;
    }
  },
  methods: {
    handleScroll() {
      // Получаем расстояние прокрутки сверху, отображаем
      let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      if (scrollTop > 60) {
        this.isActive = true;
      } else {
        this.isActive = false;
      }
    }
  },
  mounted () {
    window.addEventListener ('scroll', this.handleScroll, true); // отслеживание (привязка) событий колеса прокрутки
  }
}
</script>

<style lang="scss">

$color-txt-navbar: #5183AB;

.navbar {
  //transition: all 0.5s ease;
  overflow: hidden;
  background: rgb(255, 255, 255);
}

.scrolled {
  transition: all 0.5s ease;
  box-shadow: 0 1px 25px rgba(110, 152, 187, 0.2);
  //padding: 5px;

  > .navbar-content {
    padding-top:0;
  }
}

.navbar-content {
  transition: all 0.5s ease;
  padding-top:15px;

  a {
    text-decoration: none;
  }

  .content__brand {

    img {
      width: 40px;
      height: 40px;
      fill: $color-txt-navbar;
      transition: all 0.5s ease;

      @media (max-device-width: 575.98px) {
        transform: scale(.8);
      }
    }

    .scrolled__logo_img{
      transform: scale(.8);
    }

    &__name {
      font-family: 'Antikvar-v2', serif;
      font-size: 26px;
      line-height: 28px;
      letter-spacing: 0.075em;
      padding-left: 10px;
      color: $color-txt-navbar;
      font-weight:bold;
      transition: all 0.5s ease;

      @media (max-device-width: 575.98px) {
        font-size: 18px;
      }

    }

    .scrolled__logo_txt {
      font-size: 22px;

      @media (max-device-width: 575.98px) {
        font-size: 16px;
      }
    }

  }
}

.content__text {
  font-family: 'Panton-SemiBold', serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  align-items: center;
  color: $color-txt-navbar;

  li {
    color: $color-txt-navbar;
    transition: all .5s ease;
  }

  a {
    color: $color-txt-navbar;
    transition: all .5s ease;
    border-radius: 12px;

    &:hover {
      color: #0f3860;
      background: rgba(139, 166, 222, 0.2);
    }
  }
}

.content__phone {
  padding: 8px;
  display: flex;

  img {
    width: 28px;
    height: 28px;
    margin: 0 8px 0 8px;
  }

  span {
    padding: 4px;
    font-family: 'Panton', serif;
    font-weight: 600;
    font-size: 22px;
    line-height: 25px;
    text-align: center;
    font-feature-settings: 'case' on;
    color: $color-txt-navbar;
  }

  @media (max-device-width: 575.98px) {
    display: none !important;
  }

}

.content__cart {
  width: 46px;
  height: 46px;
  margin-left: 16px;
  border-radius: 9px;
  transition: all .5s ease;
  background: rgba(139, 166, 222, 0.15);

  &:hover {
    background: rgba(139, 166, 222, 0.3);
  }

  &:active {
    background: rgba(139, 166, 222, 0.1);
  }

  .cart-box-relative {
    position: relative;
    width: 46px;
    height: 46px;

    .cart-quantity {
      position: absolute;
      top: -6px;
      left: 34px;
      min-width: 18px;
      min-height: 18px;
      background: $color-txt-navbar;
     // text-align: center;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;

      &__number {
        font-family: 'Panton', serif;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 12px;
        padding-left: 1px;
        //padding: 4px;
        color: #ffffff;
      }
    }
  }

  button {
    width: 100%;
    height: 100%;
    //border: 2px solid rgba(22, 48, 100, 0.7);
    mask-image:  url("~@/assets/images/cart.svg");
    background-color: $color-txt-navbar;
    transition: all .2s ease;

    &:hover {
      background-color: #25507c;
    }
  }
}


</style>
