import { createStore } from 'vuex'
import axios from "axios";

function updateLocalStorage(cart) {
    localStorage.setItem('cart', JSON.stringify(cart))
}

export default createStore({
    state: {
        products: [],
        cart: []
    },
    getters: {
        PRODUCTS(state) {
            return state.products;
        },
        CART(state) {
            return state.cart;
        },
        productQuantity: state => product => {
            const item = state.cart.find(i => i.id === product.id)

            if (item) return item.quantity
            else return null
        }
    },
    mutations: {
        SET_PRODUCTS_TO_STATE: (state, products) => {
            state.products = products;
        },
        SET_TO_CART (state, product) {
            let item = state.cart.find( i => i.id === product.id)
            let minlot = product.minimum_lot
            let amInPack = product.amount_in_package
            let qNumder = minlot * amInPack
            console.log(qNumder)
            if (item) {
                item.quantity++
            } else {
                state.cart.push({...product, quantity: qNumder})
            }
            updateLocalStorage(state.cart)
        },
        REMOVE_FROM_CART: (state, index) => {
            state.cart.splice(index, 1)
            updateLocalStorage(state.cart)
        },
        DELETE_FROM_CART: (state) => {
            state.cart = []
            updateLocalStorage(state.cart)
        },
        INCREMENT: (state, index) => {
            state.cart[index].quantity++
            updateLocalStorage(state.cart)
        },
        DECREMENT: (state, index) => {
            if (state.cart[index].quantity > (state.cart[index].minimum_lot * state.cart[index].amount_in_package) ) {
                state.cart[index].quantity--
                updateLocalStorage(state.cart)
            }
        },
        updateCartFromLocalStorage(state) {
            const cart = localStorage.getItem('cart')
            if (cart) {
                state.cart = JSON.parse(cart)
            }
        }
    },
    actions: {
        GET_PRODUCTS_FROM_API({commit}) {
            return axios('/json.php', {
                //return axios('http://localhost:3000/products', {
                method: "GET"
            })
                .then((products) => {
                    commit("SET_PRODUCTS_TO_STATE", products.data);
                    return products;
                })
                .catch((error) => {
                    console.log(error)
                    return error;
                })
        },
        // SEND_MAIL_JSON({commit}) {
        //     // return axios('/json.php', {
        //     return axios.post('/mailer.php', state.cart.data
        //     )
        //         .then((products) => {
        //             commit("SET_PRODUCTS_TO_STATE", products.data);
        //             return products;
        //         })
        //         .catch((error) => {
        //             console.log(error)
        //             return error;
        //         })
        // },
        ADD_TO_CART({commit}, product) {
            commit('SET_TO_CART', product)
        },
        DELETE_FROM_CART({commit}, index) {
            commit('REMOVE_FROM_CART', index)
        },
        INCREMENT_CART_ITEM({commit}, index) {
            commit('INCREMENT', index)
        },
        DECREMENT_CART_ITEM({commit}, index) {
            commit('DECREMENT', index)
        },
    },
    modules: {
    }
})