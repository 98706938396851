<template>
  <div class="d-flex flex-column justify-content-between align-self-stretch catalog-item">
    <div class="catalog-item__image">
      <img :src=" require('../assets/images/bottle/' + product_data.image) " alt="#">
    </div>
    <div class="d-flex flex-column catalog-item__body">
      <div class="catalog-item__body__name">
        <p><span class="brand">{{ product_data.brand }}</span></p>
        <p><span class="name">{{ product_data.name }}</span></p>
        <p><span class="origin">{{ product_data.origin }}</span></p>
      </div>
      <div class="d-flex justify-content-between catalog-item__info">
          <div class="d-flex align-items-center catalog-item__info--type"><p>{{ product_data.type }}</p></div>
          <div class="d-flex align-items-center catalog-item__info--volume"><p>объём: <b>{{ product_data.volume }}</b></p></div>
      </div>
      <div class="d-flex justify-content-between catalog-item__order">
        <div class="d-flex flex-column justify-content-between align-items-center catalog-item__order--text-1">
          <p>Минимальный заказ: {{ product_data.minimum_lot }} упак.</p>
          <p>Количество в упаковке: {{ product_data.amount_in_package }} шт.</p>
        </div>
      </div>
      <div class="d-flex justify-content-between catalog-item__panel">
        <div class="d-flex justify-content-center align-items-center catalog-item__panel--price">
          <p>{{ product_data.price }}</p><span class="d-flex rub"><div></div></span>
        </div>
        <div class="d-flex justify-content-center align-items-center catalog-item__panel--basket">
          <button class="d-flex btn-primary" @click="addToCart">Купить<img class="ms-auto" src="~@/assets/images/shopping-cart-add.svg" alt="купить"></button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CatalogItem",
  props: {
    product_data: {
      type: Object,
      default() {
        return {
        }
      }
    }
  },
  data() {
    return {
      counter: 1,
    }
  },
  methods: {
    addToCart() {
      this.$emit('addToCart',this.product_data);
    }
  },
  mounted() {
  }
}

</script>

<style lang="scss">
$color-card: #F5F5F5;
$color-p-default: #12558E;

// Маленькие устройства (телефоны с горизонтальной ориентацией, 576 пикселей и выше)
@media (min-width: 576px) {  }

// Средние устройства (планшеты, ширина 768px и выше)
@media (min-width: 768px) {  }

// Большие устройства (десктопы, ширина 992px и выше)
@media (min-width: 992px) {  }

// Устройства X-Large (большие настольные компьютеры, ширина 1200 пикселей и выше)
@media (min-width: 1200px) {}

// XX-большие устройства (большие настольные компьютеры,  ширина 1400 пикселей и выше)
@media (min-width: 1400px) {}

.catalog-item {
  width: 320px;
  //height: 630px;
  background: rgba(255, 255, 255, 0.95);
  box-shadow: 0 7px 21px rgb(0 28 126 / 17%), inset 0px -10px 16.1616px rgb(223 240 255 / 75%);
  backdrop-filter: blur(15px);
  border-radius: 30px;
  padding: 22px;
  margin-bottom: 50px;
  transition: all .6s ease;
  z-index: 11;
  cursor: pointer;
  user-select: none;


  &:hover {
    box-shadow: 0 15px 40px rgb(0 28 126 / 22%), inset 0px -5px 16px rgba(148, 217, 242, 0.65);
    z-index: 55;
  }

  @media (max-device-width: 575.98px) {
    margin-bottom: 26px;
  }

  &__image {
    width: 276px;
    height: 300px;
    background: $color-card;
    border-radius: 18px;
    pointer-events: none;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &__body {

    &__name {
      text-align: center;
      font-family: 'Panton-Bold', serif;
      font-style: normal;
      font-weight: 400;
      color: $color-p-default;
      padding: 22px 0 12px 0;

      p {
        margin: 0;
      }

      .brand {
        font-size: 22px;
        line-height: 28px;
      }

      .name {
        font-size: 26px;
        line-height: 36px;
      }

      .origin {
        font-family: 'Panton', serif;
        font-size: 18px;
      }
    }
  }

  &__info {
    height: 32px;
    margin: 0 0 12px 0;

    &--type {
      background: #DBFFE7;
      border-radius: 10px;
      padding: 0 14px;

      p {
        font-family: 'Panton', serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 18px;
        text-align: center;
        color: $color-p-default;
        margin: 0;
      }
    }

    &--volume {
      background: rgba(255, 161, 19, 0.15);
      border-radius: 10px;
      padding: 0 14px;

      p {
        font-family: 'Panton', serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 18px;
        text-align: center;
        color: $color-p-default;
        margin: 0;
      }
    }
  }

  &__order {
    height: 42px;
    margin: 0 0 12px 0;

    &--text-1 {
      width: 100%;
      background: #e9f3f8;
      border-radius: 10px;
      padding: 4px 14px;

      p {
        font-family: 'Panton', serif;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        text-align: center;
        color: $color-p-default;
        margin: 0;
      }
    }
  }

  &__panel {
    height: 46px;

    &--price {
      width: 100px;
      height: 46px;

      p{
        font-family: 'Panton-Bold', serif;
        font-style: normal;
        font-weight: 700;
        font-size: 28px;
        line-height: 32px;
        text-align: center;
        color: $color-p-default;
        margin: 0 5px 0 0;
      }

      .rub {
        width: 22px;
        height: 22px;
        background: rgba(82, 117, 155, 0.15);
        border-radius: 4px;
        justify-content: center;
        align-items: center;

        div {
          display: block;
          width: 14px;
          height: 14px;
          mask-image: url("~@/assets/images/rub.svg");
          mask-repeat: no-repeat;
          background-color: $color-p-default;
          margin: 0;
          transform: scale(1.2);
        }
      }
    }

    &--basket {
      height: 46px;
      background: linear-gradient(90deg, rgba(178, 254, 250, 0.5) 0%, rgba(14, 210, 247, 0.5) 100%);
      outline: solid 4px #fff;
      border-radius: 12px;
      transition: all .4s ease;

      &:hover {
        outline: solid 4px rgba(63, 90, 201, 0.33);
      }

      button {
        width: 164px;
        height: 46px;
        font-family: 'Panton-Bold', serif;
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 32px;
        color: $color-p-default;
        background: rgba(255, 255, 255, 0.0);
        border: none;
        transition: all .3s ease;
        justify-content: center;
        align-items: center;
        padding-left: 25px;
        border-radius: 12px;

        &:active {
          box-shadow: inset 0 0 110px rgba(0, 0, 0, 0.2);
        }
      }
    }
  }
}

</style>