<template>
  <HeaderNavbar/>
  <div>
<!--      data-bs-spy="scroll"-->
<!--      data-bs-target="#navbar-header"-->
<!--      data-bs-root-margin="0px 0px -40%"-->
<!--      data-bs-smooth-scroll="true"-->
<!--      class="scrollspy-example"-->
<!--      tabindex="0"-->
<!--  >-->
    <SectionHome/>
    <SectionHistory/>
    <SectionCatalog/>
    <SectionDelivery/>
    <SectionContacts/>
    <SectionFooter/>
  </div>
</template>

<script>

import HeaderNavbar from "@/components/HeaderNavbar";
import SectionHome from "@/components/SectionHome";
import SectionHistory from "@/components/SectionHistory";
import SectionCatalog from "@/components/SectionCatalog";
import SectionDelivery from "@/components/SectionDelivery";
import SectionContacts from "@/components/SectionContacts";
import SectionFooter from "@/components/SectionFooter";

export default {
  name: 'App',
  components: {
    HeaderNavbar,
    SectionHome,
    SectionHistory,
    SectionCatalog,
    SectionDelivery,
    SectionContacts,
    SectionFooter
  },
  mounted() {
    this.$store.commit('updateCartFromLocalStorage')
  }
}
</script>

<style lang="scss">

@import "@/assets/fonts/fonts.css";

#app {
  scroll-behavior: smooth;
}

</style>
