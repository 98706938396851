<template>
  <div class="v-cart">
    <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="false">
      <div class="modal-dialog" :class="{'modal-dialog-centered': isElVisible===3}">
        <div v-if="cart_length<=0" class="modal-content">
          <div class="d-flex flex-column step-completed__box ">
            <div class="d-flex justify-content-between align-items-center step-completed__box__header">
              <div class="svg-completed"><img src="~@/assets/images/completed.svg" alt=""></div>
            </div>
            <div class="d-flex flex-column justify-content-center align-items-center step-completed__box__body">
              <span class="cart-empty-head">Ваша корзина пуста</span>
              <span class="cart-empty-body">Посмотрите предложения на главной странице, воспользуйтесь каталогом</span>
            </div>
            <div class="d-flex flex-column justify-content-between step-completed__box__footer">
              <p class="call-request">Остались вопросы ? <a href="">Оставьте заявку</a>, мы Вам поможем!</p>
            </div>
          </div>
        </div>
        <div v-else class="modal-content">
          <!--          ############## Этап 1 заказа ##################-->
          <div v-show="isElVisible===1" class="step-cart">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Корзина</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="d-flex flex-column modal-body">
              <v-cart-item
                  v-for="(product, index) in cart_data"
                  :key="product.id"
                  :cart_item_data="product"
                  @deleteFromCart="deleteFromCart(index)"
                  @increment="increment(index)"
                  @decrement="decrement(index)"
              />
            </div>
            <div class="d-flex justify-content-between modal-footer">
              <div class="d-flex justify-content-start align-items-center total-cost">
                <span class="total-cost__text">Итого:</span>
                <span class="total-cost__number">{{cartTotalCost}}</span>
                <div class="d-flex total-cost__rub"><div></div></div>
              </div>
              <div class="box-show-next">
                <button @click="toggleShowNext">Оформить заказ</button>
              </div>
            </div>
          </div>
          <!--          ############## Этап 2 заказа ##################-->
          <div v-show="isElVisible===2" class="step-address">
            <div class="modal-header d-flex justify-content-center">
              <h5 class="modal-title" id="exampleModalLabel">Оформление заказа</h5>
            </div>
            <div class="d-flex flex-column modal-body">
              <div class="form-select-group">
                <label for="exampleDataList" class="form-label">Адрес доставки: <i>Выберите из списка...</i></label>
                <select v-model="form.client_city" class="form-select" aria-label=".form-select-lg example">
                  <option selected>Выберете из списка... </option>
                  <option
                      v-for="city in cities"
                      :key="city.id"
                      :value="city.name"
                  >
                    {{city.name}}
                  </option>
                </select>
              </div>
              <div class="d-flex flex-column form-panel">
                <div class="input-group input-group form-panel__street form-panel__child">
                  <span class="input-group-text " id="inputGroup-sizing-default">Улица</span>
                  <input type="text" v-model="form.client_street" class="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-default" id="validationCustom01">
                </div>
                <div class="d-flex">
                  <div class="input-group input-group form-pane__house form-panel__child">
                    <span class="input-group-text" id="inputGroup-sizing-default">Дом</span>
                    <input type="text" v-model="form.client_house" class="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-default">
                  </div>
                  <div class="input-group input-group form-panel__qrt form-panel__child">
                    <span class="input-group-text" id="inputGroup-sizing-default">Квартира</span>
                    <input type="text" v-model="form.client_apt" class="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-default">
                  </div>
                </div>
                <div class="input-group input-group form-panel__name form-panel__child">
                  <span class="input-group-text" id="inputGroup-sizing-default">Ваше ФИО</span>
                  <input type="text" v-model="form.client_name" class="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-default">
                </div>
              </div>
              <div class="d-flex form-panel">
                <div class="input-group input-group form-panel__phone form-panel__child">
                  <span class="input-group-text" id="inputGroup-sizing-default">Телефон</span>
                  <input type="text" v-model="form.client_phone" class="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-default">
                </div>
                <div class="input-group input-group form-panel__mail form-panel__child">
                  <span class="input-group-text" id="inputGroup-sizing-default">e-mail</span>
                  <input type="text" v-model="form.client_email" class="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-default">
                </div>
              </div>
              <span class="cart-delivery">стоимость доставки согласовывается с менеджером отдельно*</span>
              <span class="cart-total-cost">Ваш заказ на сумму: {{cartTotalCost}} ₽ </span>
            </div>
            <div v-if="isElVisible===2" class="d-flex justify-content-between modal-footer">
              <span class="text-privacy-policy">
                Подтверждая заказ, Вы соглашаетесь с условиями
                <a href="#">политики конфиденциальности</a> и
                <a href="#">правилами продажи.</a>
              </span>
              <div class="box-show-back">
                <button @click="toggleShowBack">Вернуться</button>
              </div>
              <div class="box-submit">
                <button @click="onSubmit">Подвердить заказ</button>
              </div>
            </div>
          </div>
          <!--          ############## Этап 2 заказа ##################-->
          <div v-show="isElVisible===3" class="step-completed">
            <div class="d-flex flex-column step-completed__box ">
              <div class="d-flex justify-content-between align-items-center step-completed__box__header">
                <span class="txt-completed">Ваш заказ успешно оформлен!</span>
                <div class="svg-completed"><img src="~@/assets/images/completed.svg" alt=""></div>
              </div>
              <div>
                <p>Данные вашего заказа отправлены на указанную вами почту</p>
                <p>Наш менеджер свяжется с Вами для уточнений деталей оплаты и доставки заказа</p>
              </div>
              <span class="thanks">Благодарим, что выбрали нас!</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vCartItem from './v-cart-item'
import {mapActions} from "vuex";
import axios from "axios";
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'

export default {
  name: "v-cart",
  components: {
    vCartItem
  },
  props: {
    cart_data: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  setup () {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      selectedCity: "",
      isElVisible: 1,
      price: null,
      form: {
        client_email: '',
        client_name:'',
        client_phone:'',
        client_city:'',
        client_street: '',
        client_house:'',
        client_apt:'',
        total_result:'',
        project_name: 'Заявка на доставку воды',
        admin_email: 'orders@istochnik-erino.ru',
        order_number: '1'
      },
      cities: [
            {
              id: 1,
              name: "Подольск",
              delivery_price: 1,
            },
            {
              id: 2,
              name: "Родники",
              delivery_price: 1,
            },
            {
              id: 3,
              name: "Знамя октября",
              delivery_price: 1,
            },
            {
              id: 4,
              name: "Фабрика 1 мая",
              delivery_price: 1,
            },
            {
              id: 5,
              name: "Тепличная",
              delivery_price: 1,
            },
            {
              id: 6,
              name: "Красная горка",
              delivery_price: 1,
            },
            {
              id: 7,
              name: "Тепличная",
              delivery_price: 1,
            },
            {
              id: 8,
              name: "Северное Бутово",
              delivery_price: 50,
            },
            {
              id: 9,
              name: "Южное Бутово",
              delivery_price: 50,
            },
            {
              id: 10,
              name: "Щербинка",
              delivery_price: 30,
            },
            {
              id: 11,
              name: "Троицк",
              delivery_price: 50,
            },
            {
              id: 12,
              name: "Коммунарка",
              delivery_price: 50,
            },
      ]
    }
  },
  validations () {
    return {
      form: {
        client_email: { required },
        client_name:{ required },
        client_phone:{ required },
        client_city:{ required },
        client_street: { required },
        client_house:{ required },
        client_apt:{ required },
      }
    }
  },
  computed: {
    cartTotalCost() {
      let result = []
      if (this.cart_data.length) {
        for (let item of this.cart_data) {
          result.push(item.price * item.quantity)
        }
        result = result.reduce(function (sum, el) {
          return sum + el;
        })
        return result;
      } else {
        return 0
      }
    },
    // cartTotalCostDelivery() {
    //   let result = []
    //   if (this.cart_data.length) {
    //     for (let item of this.cart_data) {
    //       result.push((item.price * (item.minimum_lot * item.amount_in_package ))  * item.quantity)
    //     }
    //     result = result.reduce(function (sum, el) {
    //       return sum + el;
    //     })
    //     return result;
    //   } else {
    //     return 0
    //   }
    // },
    cart_length() {
      return this.$store.state.cart.length;
    }
  },
  methods: {
    ...mapActions([
      'DELETE_FROM_CART',
      'INCREMENT_CART_ITEM',
      'DECREMENT_CART_ITEM'
    ]),
    deleteFromCart(index) {
      this.DELETE_FROM_CART(index)
    },
    increment(index) {
      this.INCREMENT_CART_ITEM(index)
    },
    decrement(index) {
      this.DECREMENT_CART_ITEM(index)
    },
    toggleShowNext() {
      //this.form.total_result = this.cartTotalCost
      this.isElVisible++
    },
    toggleShowBack() {
      this.isElVisible--
    },
    submitConfirmOrder() {
      this.isElVisible++
      setTimeout(() =>{
        window.location.assign('/');
      }, 6000)
    },
    onSubmit() {
      let arrayCart = this.cart_data;
      let arrayForm = this.form;

      const formData = new FormData();
      formData.append('Products', JSON.stringify(arrayCart));
      formData.append('Form', JSON.stringify(arrayForm));

      console.log(JSON.parse(JSON.stringify(formData)));

      axios.post(
          // "http://test/mail_post.php",
          "/mail_post.php",
          formData, {
            headers: {
              'content-type': 'application/x-www-form-urlencoded'
            }
          }
      )
          // eslint-disable-next-line no-unused-vars
      .then((response) => {
        console.log('SUCCESS!!');
        console.log(response.data);
        this.submitConfirmOrder()
      })
          // eslint-disable-next-line no-unused-vars
      .catch((error) => {
        console.log('FAILURE!!');
      });
    }
  },
  watch: {
    cartTotalCost(newValue) {
      this.form.total_result = newValue;
      console.log(this.form.total_result)
    }
  },
  mounted() {
  }
}
</script>

<style lang="scss">
$color-card-t: #2E6EA7;
$color-card: #ffffff;
$color-p-default: #12558E;
$background-forms: #ebebeb;

  .v-cart {
    .modal {
      --bs-modal-width: 662px !important;
    }

    .modal-content {
      min-width: 662px;
      //min-height: 85vh;
      background: #FFFFFF;
      border: 8px solid #F4F2F3;
      border-radius: 36px;
      padding: 20px;
      margin: 0 0 15vh 0;

      @media (max-device-width: 575.98px) {
        min-width: 0;
        min-height: 0;
        padding: 12px;
        border: 4px solid #F4F2F3;
        border-radius: 26px;
      }

      .cart-delivery {
        color: $color-p-default;
        font-family: 'Panton', serif;
        font-style: italic;
        font-size: 18px;
        line-height: 38px;
        font-weight: 400;
        text-align: center;
        padding: 10px;
        margin-top: 10%;
      }

      .cart-total-cost {
        color: $color-p-default;
        font-family: 'Panton', serif;
        font-style: normal;
        font-size: 28px;
        line-height: 38px;
        font-weight: 600;
        text-align: center;
        padding: 10px;
        margin-top: 10%;
        border: 6px solid #F4F2F3;
        border-radius: 18px;
      }

      .step-cart, .step-address {
        display: flex;
        flex-flow: column nowrap;
        justify-content: space-between;
        //min-height: 832px;

        @media (max-device-width: 575.98px) {
          min-height: 632px;
        }

        h5 {
          font-family: 'Panton-Bold', serif;
          font-style: normal;
          font-weight: 700;
          font-size: 32px;
          line-height: 42px;
          color: $color-p-default;
          margin-left: 10px;
        }

        .form-panel {

          @media (max-device-width: 575.98px) {
            justify-content: center !important;
            flex-direction: column;
          }

          &__child {
            padding: 10px;

            span {
              color: $color-p-default;
              font-family: 'Panton', serif;
              font-style: normal;
              font-weight: 600;
              background:#F4F2F3 ;
              border: 6px solid #F4F2F3;
              border-radius: 14px;
            }

            input {
              color: $color-p-default;
              font-family: 'Panton', serif;
              font-style: normal;
              font-weight: 400;
              border: 6px solid #F4F2F3;
              border-radius: 14px;
            }
          }
        }
      }

      .form-select-group {
        padding: 10px;

        label{
          margin-left: 15px;
          color: $color-p-default;
          font-family: 'Panton', serif;
          font-style: normal;
          font-weight: 600;
        }

        select {
          color: $color-p-default;
          font-family: 'Panton', serif;
          font-style: normal;
          font-weight: 400;
          border: 6px solid #F4F2F3;
          border-radius: 14px;
        }
      }

      .step-cart {
        h5 {
          @media (max-device-width: 575.98px) {
            font-size: 26px;
          }
        }
      }

      .step-address {
        h5 {
          @media (max-device-width: 575.98px) {
            font-size: 26px;
            margin-left: 0;
          }
        }
      }

      .step-completed {
        display: flex;
        flex-flow: column nowrap;
        justify-content: space-around;
        align-items: flex-start;
        //min-height: 876px;

        @media (max-device-width: 575.98px) {
          //min-height: 632px;
        }

        &__box {
          color: $color-p-default;
          font-family: 'Panton', serif;
          font-style: normal;
          font-weight: 600;
          border: 6px solid #F4F2F3;
          border-radius: 14px;
          margin: 2%;
          padding: 2%;

          &__header {
            margin-bottom: 10%;

            .txt-completed {
              font-family: 'Panton', serif;
              font-style: normal;
              font-weight: 700;
              font-size: 36px;
              line-height: 46px;
              color: $color-p-default;

              @media (max-device-width: 575.98px) {
                font-size: 32px;
                line-height: 38px;
              }
            }
          }

          &__body {
            .cart-empty-head {
              font-family: 'Panton-Bold', serif;
              font-style: normal;
              font-weight: 700;
              font-size: 36px;
              line-height: 46px;
              color: $color-p-default;
              text-align: center;

              @media (max-device-width: 575.98px) {
                font-size: 32px;
                line-height: 38px;
              }
            }

            .cart-empty-body {
              font-family: 'Panton-Bold', serif;
              font-style: normal;
              font-weight: 700;
              font-size: 22px;
              line-height: 32px;
              color: $color-p-default;
              text-align: center;
            }
          }

          &__footer {
            .call-request {
              font-family: 'Panton', serif;
              font-style: normal;
              font-weight: 600;
              font-size: 18px;
              line-height: 28px;
              color: #46698b;
              margin: 10% 0 0 0;
              text-align: center;
            }
          }

          p {
            font-family: 'Panton', serif;
            font-style: normal;
            font-weight: 400;
            font-size: 22px;
            line-height: 32px;
            color: $color-p-default;
            text-align: left;

            @media (max-device-width: 575.98px) {
              font-size: 18px;
              line-height: 26px;
            }
          }

          .thanks {
            font-family: 'Panton', serif;
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            line-height: 28px;
            color: $color-p-default;
            margin: 5% 0 5% 0;
            text-align: center;


            @media (max-device-width: 575.98px) {
              font-size: 20px;
              line-height: 30px;
            }
          }
        }
      }

      .modal-header {
        background: $color-card;
        border: 8px solid $background-forms;
        border-radius: 25px;
        padding: 10px;

        @media (max-device-width: 575.98px) {
          padding: 4px;
        }

        button {
          margin-right: 0;
        }
      }

      .modal-body {
        padding: 0;
        margin: 28px 0 14px 0;

        @media (max-device-width: 575.98px) {
          margin: 14px 0 6px 0;
        }

        .form-select {
          cursor: pointer;
        }
      }

      .modal-footer {
        border: none;
        padding:0;
        margin: 0;

        .text-privacy-policy {
          font-family: 'Panton', serif;
          font-style: normal;
          font-weight: 100;
          font-size: 16px;
          line-height: 18px;
          color: $color-p-default;
          text-align: center;
          margin-top: 5%;
          padding: 0 15% 2% 15%;

          @media (max-device-width: 575.98px) {
            font-size: 14px;
            line-height: 16px;
            padding: 0 0 10px 0;
          }
        }

        .total-cost {
          width: 48%;
          margin: 0;

          &__text {
            font-family: 'Panton-Bold', serif;
            font-style: normal;
            font-weight: 800;
            font-size: 26px;
            line-height: 36px;
            color: $color-p-default;
            margin-left: 8px;
            margin-right: 15%;

            @media (max-device-width: 575.98px) {
              font-size: 20px;
              margin-right: 5%;
            }
          }

          &__number {
            font-family: 'Panton-Bold', serif;
            font-style: normal;
            font-weight: 400;
            font-size: 26px;
            line-height: 36px;
            text-align: center;
            color: $color-p-default;
            margin-right: 5px;

            @media (max-device-width: 575.98px) {
              font-size: 20px;
            }
          }

          &__rub {
            width: 20px;
            height: 20px;
            background: rgba(82, 117, 155, 0.15);
            border-radius: 4px;
            justify-content: center;
            align-items: center;

            div {
              display: block;
              width: 14px;
              height: 14px;
              mask-image: url("~@/assets/images/rub.svg");
              mask-repeat: no-repeat;
              background-color: $color-p-default;
              margin: 0;
              transform: scale(1.1);
            }
          }
        }

        .box-show-next {
          width: 48%;
          margin: 0;

          button {
            width: 100%;
            height: 60px;
            background: linear-gradient(90deg, rgba(178, 254, 250, 0.5) 0%, rgba(14, 210, 247, 0.5) 100%);
            border-radius: 18px;
            font-family: 'Panton-Bold', serif;
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            line-height: 100%;
            align-items: center;
            text-align: center;
            color: $color-p-default;
            border: none;
            transition: all .4s ease;
            outline: solid 2px #fff;

            @media (max-device-width: 575.98px) {
              height: 46px;
              font-size: 18px;
            }

            &:hover {
              outline: solid 2px #c5d2de;
            }
          }
        }

        .box-show-back {
          width: 48%;
          margin: 0;

          button {
            width: 100%;
            height: 60px;
            background: rgba(187, 223, 221, 0.3);
            border-radius: 18px;
            font-family: 'Panton', serif;
            font-style: normal;
            font-weight: 100;
            font-size: 24px;
            line-height: 100%;
            align-items: center;
            text-align: center;
            color: $color-p-default;
            border: none;
            transition: all .4s ease;
            outline: solid 2px #fff;

            @media (max-device-width: 575.98px) {
              height: 46px;
              font-size: 18px;
            }

            &:hover {
              outline: solid 2px #c5d2de;
            }
          }
        }

        .box-submit {
          width: 48%;
          margin: 0;

          button {
            width: 100%;
            height: 60px;
            background: linear-gradient(90deg, rgba(178, 254, 250, 0.5) 0%, rgba(14, 210, 247, 0.5) 100%);
            border-radius: 18px;
            font-family: 'Panton-Bold', serif;
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            line-height: 100%;
            align-items: center;
            text-align: center;
            color: $color-p-default;
            border: none;
            transition: all .4s ease;
            outline: solid 2px #fff;

            @media (max-device-width: 575.98px) {
              height: 46px;
              font-size: 18px;
            }

            &:hover {
              outline: solid 2px #c5d2de;
            }
          }
        }
      }
    }
  }
</style>